import { delay, routes } from '../Utils/Constants'
import { CustomInfo } from '../Utils/Functions'

export function CosaFaccio() {
  return (
    <div>
      <CustomInfo title={routes.cosaFaccio.title} text={routes.cosaFaccio.textSmall} image='/Image/1' />
      <CustomInfo title='' text={routes.cosaFaccio.text} image='/Image/2' delay={delay} />
    </div >
  )
}