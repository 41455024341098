import { HashRouter, Route, Routes, } from './Utils/Imports'
import { routes } from './Utils/Constants'
import { TopBar, BottomBar } from './Components/Bar'

function App() {
  return (
    <HashRouter>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <TopBar />
        <div style={{ flex: 1 }}>
          <Routes>
            <Route path={routes.home.path} element={routes.home.element} />
            <Route path={routes.chiSono.path} element={routes.chiSono.element} />
            <Route path={routes.cosaFaccio.path} element={routes.cosaFaccio.element} />
            <Route path={routes.passioni.path} element={routes.passioni.element} />
          </Routes>
        </div>
        <BottomBar />
      </div>
    </HashRouter>
  )
}

export default App