import { useNavigate, useState, useEffect, motion } from './Imports'
import { baseColor, baseShadow, motionSlide, motionSlideUp, motionFadeIn, smallWindow } from './Constants'


export function CustomInfo({ title, path = ``, text, image, isBig = false, delay = 0 }: { title: string, path?: string, text: string[], image: string, isBig?: boolean, delay?: number }) {
    const navigate = useNavigate()
    const [isSmallWindow, setIsSmallWindow] = useState(window.innerWidth < smallWindow)
    const [isColumn, setIsColumn] = useState(isSmallWindow || isBig)
    const [isBigImage, setIsBigImage] = useState(isBig && !isSmallWindow)
    const row = title === `` ? 'row-reverse' : 'row'

    function handleButtonClick(path: string) {
        navigate(path)
        window.scrollTo(0, 0)
    }

    useEffect(() => {
        const handleResize = () => {
            const x = window.innerWidth < smallWindow
            if (x !== isSmallWindow) {
                setIsSmallWindow(x)
                setIsColumn(x || isBig)
                setIsBigImage(isBig && !x)
            }
        }
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [isSmallWindow, isBig])

    return (
        <div style={{ margin: '1.5rem' }}>
            <motion.div {...motionSlide(delay)}>
                <h2 style={{ color: baseColor, paddingLeft: '20%', textAlign: 'left', paddingBottom: '1rem' }}>{title}</h2>
            </motion.div>
            <div style={{ display: 'flex', flexDirection: isColumn ? 'column' : row, gap: '2rem', alignItems: 'center' }}>
                <motion.div {...motionFadeIn(delay)}>
                    <svg style={{ flexShrink: 0, width: isBigImage ? '45rem' : '15rem', height: isBigImage ? '30rem' : '15rem', borderRadius: '3%', boxShadow: baseShadow }}>
                        <image
                            href={image + '.jpeg'}
                            width='100%'
                            height='100%'
                            preserveAspectRatio='xMidYMid slice'
                        />
                    </svg>
                </motion.div>
                <div>
                    <motion.div {...motionSlideUp(delay + 0.5)}>
                        {text.map((text, index) => (
                            <div key={index} dangerouslySetInnerHTML={{ __html: text }} style={{ marginTop: '0.2rem', textAlign: 'justify' }} />
                        ))}
                    </motion.div>
                    <motion.div {...motionFadeIn(delay + 1)}>
                        <button hidden={path === ``} onClick={() => handleButtonClick(path)} style={{ margin: '1rem', background: 'none', border: 'none', color: 'black' }}>
                            Scopri di più <span style={{ color: baseColor }}>→</span>
                        </button>
                    </motion.div>
                </div>
            </div>
        </div >
    )
}