import { routes, delay } from '../Utils/Constants'
import { CustomInfo } from '../Utils/Functions'

export function Home() {
  return (
    <div>
      <CustomInfo title={routes.chiSono.title} path={routes.chiSono.path} text={routes.chiSono.textSmall} image='/Damiano/2' />
      <CustomInfo title={routes.cosaFaccio.title} path={routes.cosaFaccio.path} text={routes.cosaFaccio.textSmall} image='/Image/1' delay={delay} />
      <CustomInfo title={routes.passioni.title} path={routes.passioni.path} text={routes.passioni.textSmall} image='/Damiano/5' delay={2 * delay} />
    </div >
  )
}