import { routes, delay } from '../Utils/Constants'
import { CustomInfo } from '../Utils/Functions'

export function Passioni() {
  return (
    <div>
      <CustomInfo title={routes.passioni.title} text={routes.passioni.text.intro} image='/Damiano/5' />
      <CustomInfo title="" text={routes.passioni.text.architettura} image='/Instagram/23' delay={delay} />
      <CustomInfo title=" " text={routes.passioni.text.animali} image='/JJ/1' delay={2 * delay} />
      <CustomInfo title="" text={routes.passioni.text.natura} image='/Instagram/12' delay={3 * delay} />
      <CustomInfo title=" " text={routes.passioni.text.sport} image='/DamianoEJJ/2' delay={4 * delay} />
    </div >
  )
}